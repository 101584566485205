import DCLoader from './DCLoader';
import { useState} from 'react';
import { CurrentPromos, PathImagePromo, PathDocumentPromo } from '../functions/Promos';
import { PathDocSetting } from '../functions/Settings';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import BottomToolbar from './ToolbarBottom'

function Promotions(props) {
    const PrefBannierePromo = 'BANNIERE_PROMO';
    const PrefBanniereHome = 'BANNIERE_HOME';
    const [firstInit, SetFirstInit] = useState(false);
    const [promos, SetPromos] = useState([]);
    const [stateModalPromo, SetStateModalPromo] = useState(false);
    const [selectedPromo, SetSelectedPromo] = useState([]);
    const handleCloseModalPromo = () => {
      if(stateModalPromo == true)
      {
        SetStateModalPromo(false);
      }
    }

    const handleClickPromo = (id) => {
      if(props.modeHome)
      {
        props.screenHandler("Promotions");
      }
      else
      {
        const p = promos.filter((promo) => promo.id === id);
        if(p)
        {
          if(p[0].document != "")
          {
            window.open(PathDocumentPromo(id));
            SetStateModalPromo(false);
          }
          else
          {
            SetSelectedPromo(p[0]);
            if(stateModalPromo == false)
            {
              SetStateModalPromo(true);
            }
          }
        }
      }
    }
    
    if(firstInit==false)
    {
        CurrentPromos(SetPromos);
        SetFirstInit(true);
    }
    var slidesToShow = 3;
    if(promos.length < 3)
      slidesToShow =  promos.length;
    var settings = {
        dots: false,
        fade:true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      /*const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };*/
 /**/
 
    const titreHome=false;
    return (
        <div id="promotions" className={props.modeHome ?"home-tile":"main-content"}>
            {props.modeHome ?
            <div>
            {titreHome ==true ? <div>
            <img src={PathDocSetting(PrefBanniereHome)} className="w-100" />
            <h1 className="text-center my-2 my-md-5 text-uppercase">Côté <span className="color-primary">promos</span></h1>
            
            </div>
            :
            <div>
              <h2 className="text-center text-uppercase color-primary d-none">Côté promos</h2>
            </div>
            }
            </div>
            :
            <div className="container-xxl">
              <h1 className="text-left my-2 my-md-5 text-uppercase titre-principal">Côté promos</h1>
            </div>
            }
           
                {props.modeHome?
                  <div>
                  {promos && promos.length > 0?
                  <Slider {...settings}>
                  {promos.map((d) =>(
                    <div className="text-center diapo" key={d.id} >
                      <div className="promo-tile" onClick={() => handleClickPromo(d.id)} >
                          <div className="row">
                            {d.image !="" && <div className="col-12"><img src={PathImagePromo(d.id)} className="w-100" /></div>}
                              { props.modeHome==false &&
                              <div>
                                {d.Price != "0"?<div className="col-4 col-prix p-0 text-center">{d.PricePrefix}<br/><span className="price">{d.Price}</span></div>:''}
                                <div className={d.Price != "0"?"col-8 col-title d-none":'col-12 col-title d-none'}><h2>{d.Title}</h2></div>
                              </div>
                              }
                          </div>
                      </div> 
                    </div>
                  ))}
                  </Slider>:<Slider></Slider>}
                  </div>
                :
                <div className="container-xxl mb-5">
                  {promos && promos.length > 0?
                  <div className="row">
                  {promos.map((d) =>(
                    <div className="col-extranet text-center mb-4" key={d.id} >
                      <div className="promo-tile" onClick={() => handleClickPromo(d.id)} >
                          <div className="row">
                            {d.image !="" && <div className="col-12"><img src={PathImagePromo(d.id)} className="mw-100" /></div>}
                            {d.Price != "0" && d.Price != ""?<div className="col-4 col-prix p-0 text-center">{d.PricePrefix}<br/><span className="price">{d.Price}</span></div>:''}
                              <div className={d.Price != "0" && d.Price != ""?"col-8 col-title d-none":"d-none"}><h2>{d.Title}</h2></div>
                          </div>
                      </div> 
                    </div>
                  ))}
                  </div>:<div className="row"></div>}
                </div>
                } 
            {!props.modeHome && 
            <Modal show={stateModalPromo} onHide={handleCloseModalPromo} size="xl" aria-labelledby="contained-modal-title-vcenter" className="partsmenmodal" centered>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    {selectedPromo.image !="" && <img src={PathImagePromo(selectedPromo.id)} className="w-100" />}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="row">
                    {selectedPromo.Price != "0"?
                      <div className="col-3 col-prix p-0 text-center">
                      {selectedPromo.PricePrefix}<br/><span className="price">{selectedPromo.Price}</span>
                      </div>
                      :""}
                      <div className={selectedPromo.Price != "0"?"col-9 col-title":"col-12 col-title text-center"}>
                        <h2>{selectedPromo.Title}</h2>
                      </div>
                      <div className="col-12">
                        <div dangerouslySetInnerHTML={{__html : selectedPromo.Content}}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center"><a onClick={() => {SetStateModalPromo(false);}}>Fermer</a></div>
              </Modal.Body>

              
            </Modal>}
            {!props.modeHome && <BottomToolbar screenHandler={props.screenHandler}/>}
        </div>
    );
}


export default Promotions;