import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DCLoader from './DCLoader';
import { GetClients, GetUser, SaveClient, DeleteUser, GetCommerciaux, MailReinitPassword, GetUserPermissions } from '../functions/Users';
import { GetTypesEquipements } from '../functions/TypeEquipements';
import { GetCriteres } from '../functions/Criteres';
import { GetClassements } from '../functions/Classements';
import { GetRemises } from '../functions/Remises';
import { GetMagasins } from '../functions/Magasins';
import { date_to_fr, no_accent } from '../functions/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faChevronLeft, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';


function ADClients(props) {

  const [firstInit, SetFirstInit] = useState(false);
  const [stateWindow, setStateWindow] = useState(0);
  const [liste, SetListe] = useState([]);
  const [displayListe, setDisplayListe] = useState([]);
  const [theRecord, SetTheRecord] = useState([]);
  const [permissions, setPermissions] = useState(0);
  const [formDisabled, setFormDisabled] = useState(true);
  
  const [userId, setUserId] = useState(0);
  const [Code, setCode] = useState("");
  const [Email, setEmail] = useState("");
  const [IdMagasin, setIdMagasin] = useState(0);
  const [IdCommercial, setIdCommercial] = useState(0);
  const [IdClassement, setIdClassement] = useState(0);
  const [IdRemise1, setIdRemise1] = useState(0);
  const [IdRemise2, setIdRemise2] = useState(0);
  const [RemiseSpecifique, setRemiseSpecifique] = useState(0);
  const [Enseigne, setEnseigne] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [Name, setName] = useState("");
  const [Password, setPassword] = useState("");
  const [Company, setCompany] = useState("");
  const [Phone, setPhone] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [Address2, setAddress2] = useState("");
  const [Zip, setZip] = useState("");
  const [City, setCity] = useState("");
  const [Nb_Apprentis, setNbApprentis] = useState(0);
  const [Activite_garage, setActiviteGarage] = useState(0);
  const [Activite_carrosserie, setActiviteCarrosserie] = useState(0);
  const [Activite_atelier, setActiviteAtelier] = useState(0);
  const [Specialiste_VL, setSpecialisteVL] = useState(0);
  const [Specialiste_PL, setSpecialistePL] = useState(0);
  const [CyberActive, setCyberActive] = useState(0);
  const [Categorie, setCategorie] = useState("");
  const [Equipements, setEquipements] = useState([]);
  const [stateModalEquipement, setStateModalEquipement] = useState(false);
  const [editEquipement, setEditEquipement] = useState({});
  const [userCriteres, setUserCriteres] = useState([]);
  const [LibMagasin, setLibMagasin] = useState("");

  const [types_equipements, setTypesEquipements] = useState([]);
  const [criteres, setCriteres] = useState([]);
  const [commerciaux, setCommerciaux] = useState([]);
  const [classements, setClassements] = useState([]);
  const [remises, setRemises] = useState([]);

  const [sortCol, setSortCol] = useState('');
  const [sortOrder, setSortOrder] = useState(0);

  const [activeFilter, setActiveFilter] = useState(0);
  const [filterATC, setFilterATC] = useState([]);
  const [filterClient, setFilterClient] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [filterVL, setFilterVL] = useState([]);
  const [filterPL, setFilterPL] = useState([]);
  const [filterActivite, setFilterActivite] = useState("");
  const [filterSPE, setFilterSPE] = useState([]);

  const [DistinctATC, setDistinctATC] = useState([]);
  const [DistinctActivite, setDistinctActivite] = useState([]);

  const [Magasins, setMagasins] = useState([]);


  let [loading, setLoading] = useState(false);

  
  if(firstInit==false)
  {
    /*if(props.idVisit > 0)
    {
      setLoading(true);
      SPSGetVisite(props.idVisit, SetTheVisit);
    }*/
    GetTypesEquipements(setTypesEquipements);
    GetCriteres(setCriteres);
    GetCommerciaux(setCommerciaux);
    GetClassements(setClassements);
    GetRemises(setRemises);
    GetMagasins(setMagasins);
    GetUserPermissions(setPermissions);
    SetFirstInit(true);
  }
  


  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(theRecord.id != undefined)
    {
      setLoading(true);
      setUserId(theRecord.id);
      setCode(theRecord.Code);
      setIdMagasin(theRecord.IdMagasin);
      setIdCommercial(theRecord.IdCommercial);
      setIdClassement(theRecord.IdClassement);
      setIdRemise1(theRecord.IdRemise1);
      setIdRemise2(theRecord.IdRemise2);
      setRemiseSpecifique(theRecord.RemiseSpecifique);
      setEnseigne(theRecord.Enseigne);
      setEmail(theRecord.Email);
      setFirstName(theRecord.FirstName);
      setName(theRecord.Name);
      setPassword("");
      setCompany(theRecord.Company);
      setPhone(theRecord.Phone);
      setMobile(theRecord.Mobile);
      setAddress(theRecord.Address);
      setAddress2(theRecord.Address2);
      setZip(theRecord.Zip);
      setCity(theRecord.City);
      setActiviteGarage(theRecord.Activite_garage);
      setActiviteCarrosserie(theRecord.Activite_carrosserie);
      setActiviteAtelier(theRecord.Activite_atelier);
      setSpecialistePL(theRecord.Specialiste_PL);
      setSpecialisteVL(theRecord.Specialiste_VL);
      setNbApprentis(theRecord.Nb_Apprentis);
      setCyberActive(theRecord.CyberActive);
      setCategorie(theRecord.Categorie);
      setEquipements(theRecord.Equipements);
      setUserCriteres(theRecord.Criteres);
      setLibMagasin(theRecord.Magasin);
      setStateWindow(1);
      setLoading(false);
    }
  }, [theRecord]);

  useEffect(() => {
    chargeLignes();
  }, [liste]);

  useEffect(() => {
    if(sortOrder == 0)
    {
      switch(sortCol)
      {
        case 'ATC':
          liste.sort((a, b) => (a.ATC > b.ATC) ? 1 : -1);
          break;
        case 'Client':
          liste.sort((a, b) => (a.Company > b.Company) ? 1 : -1);
          break;
        case 'Code':
          liste.sort((a, b) => (a.Code > b.Code) ? 1 : -1);
          break;
        case 'VL':
          liste.sort((a, b) => (a.Specialiste_VL > b.Specialiste_VL) ? 1 : -1);
          break;
        case 'PL':
          liste.sort((a, b) => (a.Specialiste_PL > b.Specialiste_PL) ? 1 : -1);
          break;
        case 'Activite':
          liste.sort((a, b) => (a.Categorie > b.Categorie) ? 1 : -1);
          break;
        case 'SPE':
          liste.sort((a, b) => (a.RemiseSpecifique > b.RemiseSpecifique) ? 1 : -1);
          break;
      }
    }
    else
    {
      switch(sortCol)
      {
        case 'ATC':
          liste.sort((a, b) => (a.ATC < b.ATC) ? 1 : -1);
          break;
        case 'Client':
          liste.sort((a, b) => (a.Company < b.Company) ? 1 : -1);
          break;
        case 'Code':
          liste.sort((a, b) => (a.Code < b.Code) ? 1 : -1);
          break;
        case 'VL':
          liste.sort((a, b) => (a.Specialiste_VL < b.Specialiste_VL) ? 1 : -1);
          break;
        case 'PL':
          liste.sort((a, b) => (a.Specialiste_PL < b.Specialiste_PL) ? 1 : -1);
          break;
        case 'Activite':
          liste.sort((a, b) => (a.Categorie < b.Categorie) ? 1 : -1);
          break;
        case 'SPE':
          liste.sort((a, b) => (a.RemiseSpecifique < b.RemiseSpecifique) ? 1 : -1);
          break;
      }
    }
  }, [sortCol, sortOrder]);

  useEffect(() => {
    var tmp = [];
    var Cats = [];
    for(var i=0; i< liste.length; i++)
    {
      if(checkFilters(liste[i]))
      {
        tmp.push(liste[i]);

        if(filterActivite.length == 0)
        {
          if(!Cats.includes(liste[i].Categorie))
            Cats.push(liste[i].Categorie);
        }
      }
    }
    setDisplayListe(tmp);
    
    if(filterActivite.length == 0)
    {
      Cats.sort((a,b) => (a > b) ? 1 : -1);
      setDistinctActivite(Cats);
    } 
    
  }, [filterATC, filterActivite, filterClient, filterCode, filterPL, filterVL, filterSPE]);

  useEffect(() => {
    if(permissions==1)
      setFormDisabled(false);
  }, [permissions]);
  

  function checkFilters(data)
  {
    var ok=true;
    // verifie chaque filtre
    if(filterClient!='')
    {
      console.log(filterClient);
      if(no_accent(data.Company.toLowerCase()).indexOf(no_accent(filterClient.toLowerCase())) == -1)
        ok=false;
    }

    if(filterCode!='')
    {
      
      if(data.Code.toLowerCase().indexOf(filterCode.toLowerCase()) == -1)
        ok=false;
    }

    if(filterATC.length > 0)
    {
      if(!filterATC.includes(data.ATC))
        ok=false;
    }

    if(filterVL.length > 0)
    {
      if(!filterVL.includes(data.Specialiste_VL))
        ok=false;
    }

    if(filterPL.length > 0)
    {
      if(!filterPL.includes(data.Specialiste_PL))
        ok=false;
    }

    if(filterSPE.length > 0)
    {
      if(!filterSPE.includes(data.RemiseSpecifique))
        ok=false;
    }

    if(filterActivite.length > 0)
    {
      if(!filterActivite.includes(data.Categorie))
        ok=false;
    }

    return ok;
  }

  function chargeLignes()
  {
    
    var ATCs = [];
    var Cats = [];
    // chargement des valeurs distinctes
    for(var i=0; i < liste.length;i++)
    {
      if(!ATCs.includes(liste[i].ATC))
        ATCs.push(liste[i].ATC);
      if(!Cats.includes(liste[i].Categorie))
        Cats.push(liste[i].Categorie);
    }
    ATCs.sort((a,b) => (a > b) ? 1 : -1);
    Cats.sort((a,b) => (a > b) ? 1 : -1);
    setDistinctATC(ATCs);
    setDistinctActivite(Cats);
    setDisplayListe(liste);
    /*if(liste.length > 0)
    {
      var lignes = new Array(liste.length);
      for(var i=0; i < liste.length;i++)
      {
        
        lignes[i] = 
          {id: liste[i].id, 
            atc: liste[i].IdCommercial, 
            client:liste[i].Company, 
            code: liste[i].Code, 
            vl:liste[i].Specialiste_VL, 
            pl:liste[i].Specialiste_PL, 
            activite: liste[i].Categorie,
            spe: liste[i].RemiseSpecifique
          };
      }
      setDataRows(lignes);
    }*/
  }
  
  const changeFilterATC = (e) => {
    if(e.target.checked)
    {
      if(!filterATC.includes(e.target.value))
        setFilterATC([...filterATC, e.target.value]);
    }
    else
    {
      if(filterATC.includes(e.target.value))
      {
        var values = filterATC.filter(val => val != e.target.value);
        setFilterATC(values);
      }
    }

  }

  const changeFilterVL = (e) => {
    if(e.target.checked)
    {
      if(!filterVL.includes(e.target.value))
        setFilterVL([...filterVL, e.target.value]);
    }
    else
    {
      if(filterVL.includes(e.target.value))
      {
        var values = filterVL.filter(val => val != e.target.value);
        setFilterVL(values);
      }
    }

  }

  const changeFilterPL = (e) => {
    if(e.target.checked)
    {
      if(!filterPL.includes(e.target.value))
        setFilterPL([...filterPL, e.target.value]);
    }
    else
    {
      if(filterPL.includes(e.target.value))
      {
        var values = filterPL.filter(val => val != e.target.value);
        setFilterPL(values);
      }
    }

  }
  const changeFilterActivite = (e) => {
    if(e.target.checked)
    {
      if(!filterActivite.includes(e.target.value))
        setFilterActivite([...filterActivite, e.target.value]);
    }
    else
    {
      if(filterActivite.includes(e.target.value))
      {
        var values = filterActivite.filter(val => val != e.target.value);
        setFilterActivite(values);
      }
    }

  }
  const changeFilterRSpe = (e) => {
    if(e.target.checked)
    {
      if(!filterSPE.includes(e.target.value))
        setFilterSPE([...filterSPE, e.target.value]);
    }
    else
    {
      if(filterSPE.includes(e.target.value))
      {
        var values = filterSPE.filter(val => val != e.target.value);
        setFilterSPE(values);
      }
    }

  }

  function searchDatas()
  {
    GetClients(SetListe);
  }
  const labelTypeEquipement = (id) => {
    var retour = '';
    const p = types_equipements.filter((r) => r.id == id);
    if(p.length > 0)
      retour = p[0]['Libelle'];

    return retour;
  }
  const handleAdd = (event) => {
    setUserId(0);
    setCode("");
    setIdCommercial(0);
    setIdClassement(0);
    setIdRemise1(0);
    setIdRemise2(0);
    setRemiseSpecifique(0);
    setEnseigne("");
    setEmail("");
    setFirstName("");
    setName("");
    setPassword("");
    setCompany("");
    setPhone("");
    setMobile("");
    setAddress("");
    setAddress2("");
    setZip("");
    setCity("");
    setActiviteGarage(0);
    setActiviteCarrosserie(0);
    setActiviteAtelier(0);
    setSpecialistePL(0);
    setSpecialisteVL(0);
    setNbApprentis(0);
    setCyberActive(0);
    setCategorie("");
    setStateWindow(1);
  };
  const handleCancelAdd= (event) => {
    /*if(props.idVisit > 0)
      props.screenHandling(0);
    else*/
    setStateWindow(0);
  };
  const handleClick = (id) => {
    setLoading(true);
    GetUser(id, SetTheRecord);
  };

  const handleSort = (col) => {
    setSortCol(col);
    if(sortOrder == 0)
      setSortOrder(1);
    else
      setSortOrder(0);
  }
  const handleFilter = () => {
    if(activeFilter==0)
      setActiveFilter(1);
    else
    setActiveFilter(0);
  }

  const AddEquipement = () => {
    setEditEquipement(emptyEquipement());
    setStateModalEquipement(true);
  };
  const handleMailReinit = () => {
    MailReinitPassword(userId, afterMailRetinit)
  };
  const afterMailRetinit = (ok) => {
    if(ok > 0)
      alert("Email de réinitialisation de mot de passe envoyé");
    else
      alert("Impossible d'envoyer l'email !");
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer ce client ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteUser(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };
  
  const afterDelete = () => {
    searchDatas();
  };

  const handleChangeCritere = (id, checked) => {
    if(checked)
    {
      // il est coché, s'il n'existe pas dans la liste des criteres, on l'ajoute
      if(!userCriteres.includes(id))
      {
        setUserCriteres([...userCriteres, id]);
      }
    }
    else
    {
      if(userCriteres.includes(id))
      {
        setUserCriteres(userCriteres.filter((r) => r != id));
      }
    }
  }


  const handleClickEquipement = (id) => {
    const p = Equipements.filter((r) => r.id == id);
    if(p.length > 0)
    {
      setEditEquipement(p[0]);
      setStateModalEquipement(true);
    }
  }

  const emptyEquipement = () => {
    const e = {
      id: (new Date().getTime()) * -1,
      IdTypeEquipement: 0,
      Marque: '',
      Quantite: 0,
      Date_Fin: ''
    }

    return e;
  }
  const handleCloseModalEquipement = () => {
    setStateModalEquipement(false);
  };

  const handleValidEquipement = () => {
    var equipts = [...Equipements];
    const p = equipts.filter((r) => r.id == editEquipement.id);
    if(p.length > 0)
    {
      for(var k in editEquipement) p[0][k]=editEquipement[k];
      //p[0] = [...editEquipement];
      
    }
    else
    {
      equipts.push(editEquipement);
    }
    setEquipements(equipts);
    handleCloseModalEquipement();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData(setStateWindow);
    
  };
  
  const saveData = (handleEnd) => {
    setLoading(true);
    const body = {
      id:userId,
      Type: "3",
      Code: Code,
      IdMagasin: IdMagasin,
      IdCommercial: IdCommercial,
      IdClassement: IdClassement,
      IdRemise1: IdRemise1,
      IdRemise2: IdRemise2,
      RemiseSpecifique : RemiseSpecifique,
      Enseigne: Enseigne,
      Email: Email,
      FirstName: FirstName,
      Name: Name,
      Password: Password,
      Company: Company,
      Phone: Phone,
      Mobile: Mobile,
      Address: Address,
      Address2: Address2,
      Zip: Zip,
      City: City,
      Activite_garage: Activite_garage,
      Activite_carrosserie: Activite_carrosserie,
      Activite_atelier: Activite_atelier,
      Specialiste_PL: Specialiste_PL,
      Specialiste_VL: Specialiste_VL,
      Nb_Apprentis: Nb_Apprentis,
      CyberActive: CyberActive,
      Categorie: Categorie,
      Equipements: Equipements,
      Criteres: userCriteres
    };
    
    SaveClient(body, handleEnd);
  };


  const handleBack = (event) => {
    props.screenHandler('');
  };

    switch(stateWindow)
    {
      case 1:

        return (
          <div className="container border border-primary">
            <div className="row headScreen px-0">
              <div className="col-2 text-start py-2"><button className="btn btn-secondary" type="button" onClick={handleCancelAdd}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-1 text-end pt-3"><h3>Client</h3></div>
              <div className="col-6 text-center pt-2"><input type="text" name="Company" value={Company} className="form-control form-control-lg form-control-xl text-center font-weight-bold" onChange={e => { setCompany(e.target.value); }}/></div>
              <div className="col-3 text-end py-2"><button className="btn btn-primary" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <DCLoader load={loading} />
            <form onSubmit={handleSubmit}>
              <div className="p-3">
                <input type="hidden" name="id" value={userId} />
                
                <div className="form-group mb-3">
                  <div className="row">
                      <div className="col-md-6">
                        <label  >Magasin</label>
                        <select className="form-select mb-3" value={IdMagasin} onChange={e => setIdMagasin(e.target.value)} disabled={formDisabled}>
                          <option value="0">Aucun</option>
                          {Magasins.map((d) =>(
                          <option key={d.id} value={d.id}>{d.Nom}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label  >Cyber Partsmen</label>
                        <div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="CyberActive" value="1"  checked={CyberActive>0?'checked':''} onChange={e => {setCyberActive(e.target.checked?1:0)}}  disabled={formDisabled}/>
                              <label className="form-check-label" htmlFor="CyberActive">Activé</label>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label  >Code</label>
                      <input type="text" name="Code" value={Code} className="form-control" onChange={e => { setCode(e.target.value); }} disabled={formDisabled}/>
                    </div>
                    <div className="col-md-4">
                      <label  >Commercial</label>
                      <select className="form-select" value={IdCommercial} onChange={e => setIdCommercial(e.target.value)} disabled={formDisabled}>
                        <option value="">Choisir...</option>
                        {commerciaux.map((d) =>(
                          <option key={d.id} value={d.id}>{d.FirstName} {d.Name}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label  >Critères</label>
                      <div>
                      {criteres.map((d) => (
                        <div className="form-check form-check-inline" key={d.id}>
                          <input className="form-check-input" type="checkbox" id={"critere"+d.id} value={d.id}  checked={userCriteres.includes(d.id)?'checked':''} onChange={e => {handleChangeCritere(d.id, e.target.checked)}} disabled={formDisabled}/>
                          <label className="form-check-label" htmlFor={"critere"+d.id}>{d.Critere}</label>
                        </div>
                      ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="row">
                    <div className="col-md-6">
                      <label  >Profil</label>
                      <input type="text" name="Categorie" value={Categorie} className="form-control" onChange={e => { setCategorie(e.target.value); }} disabled={formDisabled}/>
                    </div>
                    <div className="col-md-6">
                      <label className="d-block" >Spécialiste</label>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="specialisteVL" value="1" checked={Specialiste_VL>0?true:false} onChange={e => {setSpecialisteVL(e.target.checked?e.target.value:0)}}  disabled={formDisabled}/>
                        <label className="form-check-label" htmlFor="specialisteVL">VL</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="specialistePL" value="1" checked={Specialiste_PL>0?true:false} onChange={e => {setSpecialistePL(e.target.checked?e.target.value:0)}}  disabled={formDisabled}/>
                        <label className="form-check-label" htmlFor="specialistePL">PL</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="row">
                    <div className="col-md-6">
                      <label  >Enseigne</label>
                      <input type="text" name="Enseigne" value={Enseigne} className="form-control" onChange={e => { setEnseigne(e.target.value); }}  disabled={formDisabled}/>
                    </div>
                    <div className="col-md-6">
                      <label  className="d-block">Activités</label>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="activiteGarage" value="1" checked={Activite_garage>0?true:false} onChange={e => {setActiviteGarage(e.target.checked?e.target.value:0)}} disabled={formDisabled}/>
                        <label className="form-check-label" htmlFor="activiteGarage">Garage</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="activiteCarrosserie" value="1" checked={Activite_carrosserie>0?true:false} onChange={e => {setActiviteCarrosserie(e.target.checked?e.target.value:0)}} disabled={formDisabled}/>
                        <label className="form-check-label" htmlFor="activiteCarrosserie">Carrosserie</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="activiteAtelier" value="1" checked={Activite_atelier>0?true:false} onChange={e => {setActiviteAtelier(e.target.checked?e.target.value:0)}} disabled={formDisabled}/>
                        <label className="form-check-label" htmlFor="activiteAtelier">Atelier intégré</label>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="form-group row mb-3">
                  <div className="col-md-6">
                    <label id="label-chantier" >Adresse</label>
                    <input type="text" name="Address" value={Address} className="form-control mb-2" onChange={e => { setAddress(e.target.value); }} disabled={formDisabled}/>
                    <input type="text" name="Address2" value={Address2} className="form-control" onChange={e => { setAddress2(e.target.value); }} disabled={formDisabled}/>
                  </div>
                  <div className="col-md-6">
                    <label id="label-chantier" >Nombre d'apprentis</label>
                    <input type="number" name="Nb_Apprentis" value={Nb_Apprentis} className="form-control mb-2" onChange={e => { setNbApprentis(e.target.value); }}  disabled={formDisabled}/>
                    
                    <div className="row">
                      <div className="col-md-4">
                        <label className="me-1">Remise 1 </label>
                        <select className="form-select d-inline w-auto" value={IdRemise1} onChange={e => setIdRemise1(e.target.value)}  disabled={formDisabled}>
                          <option value="">Choisir...</option>
                          {remises.map((d) =>(
                            <option key={d.id} value={d.id}>{d.Label}</option>
                            ))}
                        </select>
                        
                      </div>
                      <div className="col-md-4">
                        <label className="me-1">Remise 2 </label>
                          <select className="form-select d-inline w-auto" value={IdRemise2} onChange={e => setIdRemise2(e.target.value)}  disabled={formDisabled}>
                            <option value="">Choisir...</option>
                            {remises.map((d) =>(
                              <option key={d.id} value={d.id}>{d.Label}</option>
                              ))}
                          </select>
                        
                      </div>
                      <div className="col-md-4">
                        <label className="py-2">Spécifique 
                          <input className="form-check-input ms-1" type="checkbox" id="remiseSpecifique" value="1" checked={RemiseSpecifique>0?true:false} onChange={e => {setRemiseSpecifique(e.target.checked?e.target.value:0)}}  disabled={formDisabled}/>
                       
                        </label>
                      </div>
                    </div>
                      
                  </div>
                
                </div>
                <div className="form-group row mb-3">
                  <div className="col-md-4">
                    <label id="label-chantier" >Code postal</label>
                    <input type="text" name="Zip" value={Zip} className="form-control" onChange={e => { setZip(e.target.value); }}  disabled={formDisabled}/>
                  </div>
                  <div className="col-md-8">
                    <label id="label-chantier" >Ville</label>
                    <input type="text" name="City" value={City} className="form-control" onChange={e => { setCity(e.target.value); }}  disabled={formDisabled}/>
                  </div>
                </div>
                <h3>Contact</h3>
                <div className="form-group row mb-3">
                  <div className="col-md-6">
                    <label id="label-chantier" >Prénom</label>
                    <input type="text" name="FirstName" value={FirstName} className="form-control" onChange={e => { setFirstName(e.target.value); }}  disabled={formDisabled}/>
                  </div>
                  <div className="col-md-6">
                    <label id="label-chantier" >Nom</label>
                    <input type="text" name="Name" value={Name} className="form-control" onChange={e => { setName(e.target.value); }} disabled={formDisabled}/>
                  </div>
                </div>
                
                <div className="form-group row mb-3">
                  <div className="col-md-6">
                    <label id="label-chantier" >Email</label>
                    <input type="text" name="Email" value={Email} className="form-control" onChange={e => { setEmail(e.target.value); }} disabled={formDisabled}/>
                  </div>
                  <div className="col-md-3">
                    <label id="label-chantier" >Téléphone</label>
                    <input type="text" name="Phone" value={Phone} className="form-control" onChange={e => { setPhone(e.target.value); }} disabled={formDisabled}/>
                  </div>
                  <div className="col-md-3">
                    <label id="label-chantier" >Mobile</label>
                    <input type="text" name="Mobile" value={Mobile} className="form-control" onChange={e => { setMobile(e.target.value); }} disabled={formDisabled}/>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-md-6">
                    <label id="label-chantier" >Mot de passe</label>
                    <input type="password" name="Password" value={Password} className="form-control" onChange={e => { setPassword(e.target.value); }} disabled={formDisabled}/>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <button type="button" className="btn btn-secondary" onClick={handleMailReinit}>Envoyer email de réinitialisation de mot de passe</button>
                  </div>
                </div>
                <div className="row">
                  <h3 className="col-8">Equipements</h3>
                  <div className="col-4 text-end"><button type="button" className="btn btn-secondary" onClick={() => AddEquipement()}><span className="d-inline d-md-none">+</span><span className="d-none d-md-inline">Ajouter</span></button> </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Equipement</th>
                          <th>Qté</th>
                          <th>Marque</th>
                          <th>Date de fin</th>
                        </tr>
                      </thead>
                      <tbody>
                      {Equipements.map((d) =>(
                      <tr key={d.id} onClick={() => handleClickEquipement(d.id)} >
                         <td>{labelTypeEquipement(d.IdTypeEquipement)}</td>
                         <td>{d.Quantite}</td>
                         <td>{d.Marque}</td>
                         <td>{date_to_fr(d.Date_Fin)}</td>
                      </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Equipement</th>
                          <th>Qté</th>
                          <th>Marque</th>
                          <th>Date de fin</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                
                
              </div>
              <Modal show={stateModalEquipement} onHide={handleCloseModalEquipement} size="xl" aria-labelledby="contained-modal-title-vcenter" className="partsmenmodal" centered>
              <Modal.Body>
                <div className="row mb-3">
                  <label className="col-md-2">Type</label>
                  <div className="col-md-8">
                    <select className="form-select" value={editEquipement.IdTypeEquipement} onChange={e => setEditEquipement({...editEquipement, IdTypeEquipement:e.target.value})}>
                      <option value="">Choisir...</option>
                      {types_equipements.map((d) =>(
                        <option key={d.id} value={d.id}>{d.Libelle}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-md-2">Quantité</label>
                  <div className="col-md-2">
                    <input type="number" className="form-control" onChange={e => setEditEquipement({...editEquipement, Quantite:e.target.value})} value={editEquipement.Quantite} />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-md-2">Marque</label>
                  <div className="col-md-8">
                    <input type="text" className="form-control"  onChange={e => setEditEquipement({...editEquipement, Marque:e.target.value})} value={editEquipement.Marque}/>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-md-2">Date fin</label>
                  <div className="col-md-8">
                    <input type="date" className="form-control"  onChange={e => setEditEquipement({...editEquipement, Date_Fin:e.target.value})} value={editEquipement.Date_Fin}/>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <button type="button" onClick={() => handleCloseModalEquipement()} className="btn btn-secondary">Annuler</button>
                  </div>
                  <div className="col-6 text-end">
                    <button type="button" onClick={() => handleValidEquipement()} className="btn btn-primary">Valider</button>
                  </div>
                </div>
              </Modal.Body>

              
              </Modal>
            </form>
                      
              
            
          </div>
        );
        break;
      default:
        var liste_vide = "";
        if(liste.length == 0)
        {
          liste_vide = <div className="lineEmptyTab">Aucun client</div>
        }
        return (
          <div className="container border border-primary" >
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Clients</h1></div>
              <div className="col-3 text-end py-2"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un client</button></div>
            </div>
            <DCLoader load={loading} />
            <table className="table">
              <tbody>
                <tr>
                  <td className="">
                    <ul className="container">
                      <li className="row bg-primary py-2">
                          <div className="col-1" onClick={() => handleSort('ATC')}>ATC {sortCol == 'ATC' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'ATC' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-4" onClick={() => handleSort('Client')}>Client  {sortCol == 'Client' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'Client' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-1" onClick={() => handleSort('Code')}>Code  {sortCol == 'Code' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'Code' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-1" onClick={() => handleSort('VL')}>VL  {sortCol == 'VL' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'VL' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-1" onClick={() => handleSort('PL')}>PL  {sortCol == 'PL' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'PL' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-2" onClick={() => handleSort('Activite')}>Activité {sortCol == 'Activite' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'Activite' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-1" onClick={() => handleSort('SPE')}>SPE  {sortCol == 'SPE' && sortOrder==1?<FontAwesomeIcon icon={faSortUp} />:''}{sortCol == 'SPE' && sortOrder==0?<FontAwesomeIcon icon={faSortDown} />:''}</div>
                          <div className="col-1 text-end">
                            <FontAwesomeIcon onClick={() => handleFilter()} icon={faFilter} />
                          </div>
                      </li>
                      {activeFilter==1?
                      <li className="row mb-2 border border-primary py-2 filtres">
                          <div className="col-1">
                            {DistinctATC.map((f, index) => (
                               <label className="d-block" key={f}><input type="checkbox" name={'fATC'+index} value={f} onChange={e => changeFilterATC((e))} checked={filterATC.includes(f)?'checked':''}/> {f!=""?f:"Sans ATC"}</label>
                            ))}
                          </div>
                          <div className="col-4"><input type="text" className="form-control" name="fClient" value={filterClient}  onChange={e => { setFilterClient(e.target.value); }}/> </div>
                          <div className="col-1"><input type="text" className="form-control" name="fCode" value={filterCode}  onChange={e => { setFilterCode(e.target.value); }}/></div>
                          <div className="col-1" >
                            <label><input type="checkbox" name="fVLOui" value="1" onChange={e => changeFilterVL((e))} checked={filterVL.includes("1")?'checked':''}/> Oui</label>
                            <label><input type="checkbox" name="fVLNon" value="0" onChange={e => changeFilterVL((e))} checked={filterVL.includes("0")?'checked':''}/> Non</label>
                          </div>
                          <div className="col-1">
                            <label><input type="checkbox" name="fPLOui" value="1" onChange={e => changeFilterPL((e))} checked={filterPL.includes("1")?'checked':''}/> Oui</label>
                            <label><input type="checkbox" name="fPLNon" value="0" onChange={e => changeFilterPL((e))} checked={filterPL.includes("0")?'checked':''}/> Non</label>
                          </div>
                          <div className="col-2">
                            <div className="filter-list">
                              {DistinctActivite.map((f, index) => (
                                  <label className="d-block" key={f}><input type="checkbox" name={'fActivite'+index} value={f} onChange={e => changeFilterActivite((e))} checked={filterActivite.includes(f)?'checked':''}/> {f}</label>
                                ))}
                            </div>
                          </div>
                          <div className="col-1" >
                            <label><input type="checkbox" name="fRSpeOui" value="1" onChange={e => changeFilterRSpe((e))} checked={filterSPE.includes("1")?'checked':''}/> Oui</label>
                            <label><input type="checkbox" name="fRSpeNon" value="0" onChange={e => changeFilterRSpe((e))} checked={filterSPE.includes("0")?'checked':''}/> Non</label>
                          </div>
                          <div className="col-1">
                            
                          </div>
                      </li>:''}
                      {displayListe.map(d => {
                        return checkFilters(d)?
                        
                            <li className="row mb-2 alternate-color py-2" key={d.id}  >
                                <div className="col-1" onClick={() => handleClick(d.id)}>{d.ATC}</div>
                                <div className="col-4" onClick={() => handleClick(d.id)}>{d.Company}</div>
                                <div className="col-1" onClick={() => handleClick(d.id)}>{d.Code}</div>
                                <div className="col-1" onClick={() => handleClick(d.id)}>{d.Specialiste_VL>0?'X':''}</div>
                                <div className="col-1" onClick={() => handleClick(d.id)}>{d.Specialiste_PL>0?'X':''}</div>
                                <div className="col-2" onClick={() => handleClick(d.id)}>{d.Categorie}</div>
                                <div className="col-1" onClick={() => handleClick(d.id)}>{d.RemiseSpecifique>0?'X':''}</div>
                                <div className="col-1 text-end">
                                  <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(d.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                                </div>
                            </li>
                        :
                        ''
                      })}
                    </ul>
                    {liste_vide}
                    
                    
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        );
        break;
    }
  
}

export default ADClients;
