export function date_to_fr(date_en)
{
    var date = new Date(date_en);
    var retour = {};

    //retour.date = date.getDate().toFixed(2)+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
    retour = date.toLocaleDateString('fr-FR');
    
    return retour;
}

export function no_accent (str) {
    const accentMap = {
        'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a',
        'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',
        'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
        'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o',
        'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',
        'ý': 'y', 'ÿ': 'y',
        'ç': 'c', 'ñ': 'n'
      };
    
      return str.replace(/[àáâãäåèéêëìíîïòóôõöùúûüýÿçñ]/g, match => accentMap[match] || match);
}